import React from 'react'

const Contact = () => {
  return (
    <div>
      Contact
    </div>
  )
}

export default Contact

import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import About from './Components/Pages/About';
import Home from './Components/Pages/Home';
import Contact from './Components/Pages/Contact';
import Articals from './Components/Pages/Articals';



function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/"  element={<Home/>}></Route>
        <Route path="/about"  element={<About/>}></Route>
        <Route path="/contact"  element={<Contact/>}></Route>
        <Route path="/articals"  element={<Articals/>}></Route>
      </Routes>
    </div>
  );
}

export default App;

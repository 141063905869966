import React from 'react'
import "./Home.css";

const About = () => {
  return (
    <div className="container-fluid" style={{ textAlign: 'left' }}>
    <div id="about" className="row about-section">
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">Who am I ?</h3>
            <span className="line mb-5"></span>
            <h5 className="mb-3">A Web Designer / Developer Located In Our Lovely Earth</h5>
            <p className="mt-20">Experienced software developer with over a year in corporate environments, currently excelling at Valgenesis.</p>
            <button className="btn btn-outline-danger"><i className="icon-down-circled2 "></i>Download My CV</button>
        </div>
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">Personal Info</h3>
            <span className="line mb-5"></span>
            <ul className="mt40 info list-unstyled">
                <li><span>Birthdate</span> : 01/03/2001</li>
                <li><span>Email</span> : sharunpshetty@gmail.com</li>
                <li><span>Phone</span> : + (91) 6361938597</li>
                <li><span>LinkedIn</span> : sharunshetty </li>
                <li><span>Address</span> :  Bangalore, India.</li>
            </ul>
            <ul className="social-icons pt-3">
                <li className="social-item"><a className="social-link" href="#" style={{ textDecoration:'none',color:'#F85C70'}}><i className="ti-facebook" aria-hidden="true"></i></a></li>
                <li className="social-item"><a className="social-link" href="#" style={{ textDecoration:'none',color:'#F85C70'}}><i className="ti-linkedin" aria-hidden="true" ></i></a></li>
                <li className="social-item" ><a className="social-link" href="#" style={{ textDecoration:'none',color:'#F85C70'}}><i className="ti-twitter" aria-hidden="true"></i></a></li>
                <li className="social-item"><a className="social-link" href="#" style={{ textDecoration:'none',color:'#F85C70'}}><i className="ti-instagram" aria-hidden="true"></i></a></li>
                <li className="social-item"><a className="social-link" href="#" style={{ textDecoration:'none',color:'#F85C70'}}><i className="ti-github" aria-hidden="true"></i></a></li>
            </ul>  
        </div>
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">My Expertise</h3>
            <span className="line mb-5"></span>
            <div className="row">
                <div className="col-1 text-danger pt-1"><i className="ti-widget icon-lg"></i></div>
                <div className="col-10 ml-auto mr-3">
                    <h6>UX Design</h6>
                    <p className="subtitle"> React, Angular, Bootstrap.</p>
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-1 text-danger pt-1"><i className="ti-paint-bucket icon-lg"></i></div>
                <div className="col-10 ml-auto mr-3">
                    <h6>Web Development</h6>
                    <p className="subtitle">ASP.net Web API, postgreSQL.</p>
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-1 text-danger pt-1"><i className="ti-stats-up icon-lg"></i></div>
                <div className="col-10 ml-auto mr-3">
                    <h6>Financial Management</h6>
                    <p className="subtitle">Stock Investor.  </p>
                    <hr/>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default About

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [imageVisible, setImageVisible] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;

      const image = document.querySelector(".brand-img");

      if (image) {
        const imageRect = image.getBoundingClientRect();
        const topThreshold = imageRect.height * 3;

        if (currentPosition > 500 && imageVisible) {
          //debugger;
          setImageVisible(false);
        } else if (currentPosition < 500 && !imageVisible) {
          setImageVisible(true);
        }
        // Check if the scroll position is above the top threshold or below the bottom threshold
        // if (currentPosition < topThreshold && !imageVisible) {
        //   setImageVisible(true);
        // } else if (currentPosition >= topThreshold && imageVisible) {
        //   setImageVisible(false);
        // }
      }

      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [imageVisible]);

  useEffect(() => {
    // Periodically check scroll position and update image visibility
    const interval = setInterval(() => {
      const currentPosition = window.scrollY;

      if (currentPosition < 500 && !imageVisible) {
        setImageVisible(true); // Set imageVisible to true to make the image visible
      } else if (currentPosition >= 500 && imageVisible) {
        setImageVisible(false); // Set imageVisible to false to hide the image
      }

      setScrollPosition(currentPosition);
    }, 10); // Check every 1000 milliseconds (1 second)

    // Cleanup: Clear interval when component unmounts
    return () => clearInterval(interval);
  }, [imageVisible]);

  return (
    <nav
      className="navbar sticky-top navbar-expand-lg navbar-light bg-white"
      data-offset-top="510"
    >
      <div className="container">
        <button
          className="navbar-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse mt-sm-20 navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item pr-4">
              <a href="/" className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item pr-4">
              <a href="/about" className="nav-link">
                About
              </a>
            </li>
            <li className="nav-item pr-4">
              <a href="#resume" className="nav-link">
                Resume
              </a>
            </li>
          </ul>
          <ul className="navbar-nav brand">
            {imageVisible && (
              <img
                src="/images/avatar.jpg"
                alt=""
                className={`brand-img ${imageVisible ? "show" : "hide"}`}
              />
            )}
            <li className="brand-txt">
              <h5 className="brand-title">Sharun Shetty</h5>
              <div className="brand-subtitle">Full Stack Web Developer</div>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item pl-4">
              <a href="#portfolio" className="nav-link">
                Portfolio
              </a>
            </li>
            <li className="nav-item pl-4">
              <a href="/articals" className="nav-link">
                Blog
              </a>
            </li>
            <li className="nav-item last-item pl-4">
              <a href="/contact" className="nav-link">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
